<template>
  <div class="navbar-custom">
    <div class="container-fluid">
      <ul class="list-unstyled topnav-menu float-end mb-0">
        <li class="dropdown d-none d-lg-inline-block">
          <a
            class="nav-link dropdown-toggle arrow-none waves-effect waves-light"
            href="/"
            target="_blank"
          >
            <i class="fe-home noti-icon"></i>
          </a>
        </li>
        <li class="dropdown notification-list topbar-dropdown">
          <a
            class="nav-link dropdown-toggle nav-user me-0 waves-effect waves-light"
            data-bs-toggle="dropdown"
            href="#"
            role="button"
            aria-haspopup="false"
            aria-expanded="false"
          >
            <img
              src="../../assets/images/users/user-1.jpg"
              alt="user-image"
              class="rounded-circle"
            />
            <span class="pro-user-name ms-1">
              {{ this.$store.getters["auth/getAuthName"] }}
              <i class="mdi mdi-chevron-down"></i>
            </span>
          </a>
          <div class="dropdown-menu dropdown-menu-end profile-dropdown">
            <!-- item-->
            <div class="dropdown-header noti-title">
              <h6 class="text-overflow m-0">Welcome ! {{ welcomeMessage }}</h6>
            </div>

            <!-- item-->
            <a
              href="javascript:void(0);"
              class="dropdown-item notify-item"
              @click="openForm"
            >
              <i class="fe-settings"></i>
              <span>Change Password</span>
            </a>

            <a
              href="javascript:void(0);"
              class="dropdown-item notify-item"
              @click="logout"
            >
              <i class="fe-log-out"></i>
              <span>Logout</span>
            </a>
          </div>
        </li>
      </ul>

      <!-- LOGO -->
      <div class="logo-box">
        <a href="javascript:void(0);" class="logo logo-dark text-center">
          <span class="logo-sm">
            <!-- <img
                     src="../../assets/images/logo-sm.png"
                     alt=""
                     height="22"
                  /> -->
            <!-- <span class="logo-lg-text-light">UBold</span> -->
          </span>
          <span class="logo-lg">
            <!-- <img
                     src="../../assets/images/logo-dark.png"
                     alt=""
                     height="20"
                  /> -->
            <!-- <span class="logo-lg-text-light">U</span> -->
          </span>
        </a>

        <a href="javascript:void(0);" class="logo logo-light text-center">
          <span class="logo-sm">
            <!-- <img
                     src="../../assets/images/logo-sm.png"
                     alt=""
                     height="22"
                  /> -->
            <span class="logo-lg-text-light">TIS</span>
          </span>
          <span class="logo-lg">
            <!-- <img
                     src="../../assets/images/logo-light.png"
                     alt=""
                     height="20"
                  /> -->
            <span class="logo-lg-text-light">TIS - INTERNAL</span>
          </span>
        </a>
      </div>

      <ul class="list-unstyled topnav-menu topnav-menu-left m-0">
        <li>
          <button class="button-menu-mobile waves-effect waves-light">
            <i class="fe-menu"></i>
          </button>
        </li>

        <li>
          <!-- Mobile menu toggle (Horizontal Layout)-->
          <a
            class="navbar-toggle nav-link"
            data-bs-toggle="collapse"
            data-bs-target="#topnav-menu-content"
          >
            <div class="lines">
              <span></span>
              <span></span>
              <span></span>
            </div>
          </a>
          <!-- End mobile menu toggle-->
        </li>
      </ul>
      <div class="clearfix"></div>
    </div>
  </div>

  <AttachFileModal :modalId="'change-password'">
    <template v-slot:header> Change Password </template>

    <template v-slot:body>
      <form id="change-password-form" @submit.prevent="updatePassword">
        <div class="mb-3">
          <label for="example-fileinput" class="form-label">New password</label>
          <input
            v-model="input.newPassword"
            type="password"
            class="form-control"
            :class="{
              'p-invalid':
                v$.input.newPassword.$error || errorFor('new_password'),
            }"
          />
          <v-errors
            :serverErrors="errorFor('new_password')"
            :vuelidateErrors="{
              errors: v$.input.newPassword.$errors,
              value: 'New password',
            }"
          ></v-errors>

          <label for="example-fileinput" class="form-label mt-2"
            >Confirm New password</label
          >
          <input
            v-model="input.confirmNewPassword"
            type="password"
            class="form-control"
            :class="{
              'p-invalid':
                v$.input.confirmNewPassword.$error ||
                errorFor('confirm_new_password'),
            }"
          />
          <v-errors
            :serverErrors="errorFor('confirm_new_password')"
            :vuelidateErrors="{
              errors: v$.input.confirmNewPassword.$errors,
              value: 'Confirm new password',
            }"
          ></v-errors>
        </div>
      </form>
    </template>

    <template v-slot:footer>
      <button type="submit" form="change-password-form" class="btn btn-light">
        Update
      </button>
    </template>
  </AttachFileModal>
</template>

<script>
import { mapActions } from "vuex";
import $ from "jquery";
import useVuelidate from "@vuelidate/core";
import { required, sameAs, minLength } from "@vuelidate/validators";
import { useToast } from "vue-toastification";
//Components
import AttachFileModal from "../../components/shared/Modal.vue";
//mixins
import validationErrors from "../../mixins/validationErrors";

export default {
  name: "Topbar",
  setup() {
    const toast = useToast();
    return { v$: useVuelidate(), toast };
  },
  components: {
    AttachFileModal,
  },
  mixins: [validationErrors],
  validations() {
    return {
      input: {
        newPassword: { required, minLength: minLength(6) },
        confirmNewPassword: { sameAs: sameAs(this.input.newPassword) },
      },
    };
  },
  data() {
    return {
      input: {
        newPassword: "",
        confirmNewPassword: "",
      },
      employees: [],
    };
  },
  methods: {
    ...mapActions(["auth/logout", "auth/changePassword"]),
    logout() {
      this["auth/logout"]()
        .then(() => {
          this.$router.push({ name: "login" });
        })
        .catch(() => {
          console.log("error");
          this.$store.dispatch("auth/logout", this.token);
          this.$router.push({ name: "login" });
        });
    },
    openForm() {
      $("#change-password").modal("show");
    },
    async updatePassword() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;

      this["auth/changePassword"](this.input)
        .then((response) => {
          this.$nextTick(() => {
            this.input.newPassword = "";
            this.input.confirmNewPassword = "";
            this.v$.$reset();
          });

          $("#change-password").modal("hide");
          this.toast.success(response.data.message);
        })
        .catch((error) => {
          this.errors = error;
        });
    },
    getShortName(fullName) {
      if (typeof fullName === "string" && fullName.trim() !== "") {
        const words = fullName.split(" ");
        if (words.length > 0) {
         return words.map(word => word[0]).join('');
        }
      }
      return "";
    },
  },
  computed: {
    
    welcomeMessage() {
      const authEmpId = this.$store.getters["auth/getAuthEmpId"];
      const employee = this.employees.find(
        (employee) => employee.emp_id === authEmpId
      );
      if (employee && employee.business_unit.length > 0) {
        return this.getShortName(employee.business_unit[0].name);
      } else {
        return ""; // Handle the case when no matching employee or business unit is found.
      }
    },
  },

  async created() {
    this.employees = await this.$store.getters["odoo/getAllEmployees"];
  },
};
</script>

<style></style>
